import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDataTable,{attrs:{"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loadingItens},scopedSlots:_vm._u([{key:"item.dt_faturamento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.dt_faturamento,'DD/MM/YYYY'))+" ")])]}},{key:"item.valor_orcado",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("formatNumber")(item.valor_orcado))+" ")])]}},{key:"item.valor_pago",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("formatNumber")(item.valor_pago))+" ")])]}},{key:"item.valor_saldo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("formatNumber")(item.valor_saldo))+" ")])]}}],null,true)},[(_vm.items.length>0)?_c('template',{slot:"body.append"},[_c('tr',{staticClass:"custom-table--header"},[_c('th',{staticStyle:{"font-size":"12px"}},[_vm._v("Total")]),_c('th',{staticClass:"text-left",staticStyle:{"font-size":"12px"}},[_vm._v("R$ "+_vm._s(_vm._f("formatNumber")(_vm.soma(_vm.items, 'valor_orcado'))))]),_c('th',{staticClass:"text-left",staticStyle:{"font-size":"12px"}},[_vm._v("R$ "+_vm._s(_vm._f("formatNumber")(_vm.soma(_vm.items, 'valor_pago'))))]),_c('th',{staticClass:"text-left",staticStyle:{"font-size":"12px"}},[_vm._v("R$ "+_vm._s(_vm._f("formatNumber")(_vm.soma(_vm.items, 'valor_saldo'))))])])]):_vm._e()],2)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }