<template>
  <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mr-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadReport('pdf')">
          <v-list-item-title>Exportar PDF</v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadReport('excel')">
          <v-list-item-title>Exportar Excel</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
        color="primary"
        class="mr-2"
        dark
        title="Remover Filtros"
        @click="removeFiltros"
    >
      <v-icon>mdi-filter-remove</v-icon>
    </v-btn>
    <HeaderControl
        v-model="model"
        :headers="headersOptions"
        :items-per-page="itemsPerPage"
        show-items-per-page
        local-storage-save-name="route-easy"
        @itemsPerPage="$emit('itemsPerPage', $event)"
    ></HeaderControl>
    <snackbar ref="snackbar"/>
  </v-toolbar>
</template>

<script>

import ApiService from "@/core/services/api.service";
import Snackbar from "@/core/components/Snackbar.vue";

export default {
  name: 'ActionsListagemContaCorrente',
  components: {Snackbar},

  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
    itemsPerPage: {
      type: Number,
    },
  },

  data: () => ({}),

  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },

    headersOptions: function () {
      return [{
        text: this.filtro&&this.filtro.tipoRelatorioId===1?'Data de Faturamento':'Produção',
        value: this.filtro&&this.filtro.tipoRelatorioId===1?'dt_faturamento':'producao',
        class: 'primary rounded-tl white--text',
        ativado: true
      }, {
        text: 'Valor Orçado',
        value: 'valor_orcado',
        class: 'primary white--text',
        ativado: true
      }, {
        text: 'Valor Pago',
        value: 'valor_pago',
        class: 'primary white--text',
        ativado: true
      }, {
        text: 'Saldo (C.C)',
        value: 'valor_saldo',
        class: 'rounded-tr primary white--text',
        ativado: true
      }]
    },
  },

  methods: {
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },

    downloadReport (type) {
      ApiService.downloadReport(type, 'conta_corrente_logistica', this.filtro, this.$refs.snackbar)
    },
  },
}
</script>
