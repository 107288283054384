<template>
  <v-form ref="form">
    <FiltroMobile :has-change="change" @search="seachFilter">
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="tipoRelatorioId"
            dense
            :items="select.tipoRelatorioId"
            item-text="descricao"
            item-value="id"
            label="Tipo de Relatório *"
            outlined
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe a Data Inicial']"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataInicial"
            clearable
            label="Data Inicial *"
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe a Data Inicial']"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataFinal"
            clearable
            label="Data Final *"
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe a Data Final']"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <empresas-select
            v-model="empresaId"
            clearable
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <producoes-select
            v-model="producaoId"
            clearable
            :empresa="empresaId"
            multiple
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <template v-slot:outros>
      </template>
    </FiltroMobile>
    <custom-snackbar ref="snackbar" />
  </v-form>
</template>

<script>
export default {
  name: 'FilterListagemContaCorrente',

  props: {
    value: {
      type: Object,
    },
  },

  data: () => ({
    change: false,
    tipoRelatorioId: null,
    dataInicial: null,
    dataFinal: null,
    empresaId: null,
    producaoId: null,
    select: {
      tipoRelatorioId: [{
        id: 1,
        descricao: 'Diário',
      }, {
        id: 2,
        descricao: 'Produção',
      }],
    },
  }),

  mounted () {
    this.tipoRelatorioId = this.value?.tipoRelatorioId
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.dataInicial = this.value?.dataInicial
    this.dataFinal = this.value?.dataFinal
  },

  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },

  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        this.$refs.snackbar.show(
            'Desculpe, algo deu errado',
            'Verifique os campos em vermelho e tente novamente',
            'danger',
            3000,
        )

        return false
      }

      this.change = false

      this.model = {
        tipoRelatorioId: this.tipoRelatorioId,
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
      }
    },
  },
}
</script>
