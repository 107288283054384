import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c(VCard,{attrs:{"disabled":_vm.form.processing,"loading":_vm.form.processing}},[_c(VCardSubtitle,{staticClass:"title pt-3"},[_vm._v(" Este pedido ainda não foi roteirizado. Você deseja informar o status manualmente? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":_vm.close}},[_vm._v("Não")]),_c(VBtn,{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.save}},[_vm._v("Sim")]),_c(VSpacer)],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }