<template>
  <div>
    <v-card>
      <v-data-table
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
        :headers="headers"
        :items="items"
        :loading="loadingItens"
      >
        <template v-slot:[`item.dt_faturamento`]="{ item }">
          <span> {{ item.dt_faturamento | moment('DD/MM/YYYY') }} </span>
        </template>
        <template v-slot:[`item.valor_orcado`]="{ item }">
          <span> R$ {{ item.valor_orcado | formatNumber }} </span>
        </template>
        <template v-slot:[`item.valor_pago`]="{ item }">
          <span> R$ {{ item.valor_pago | formatNumber }} </span>
        </template>
        <template v-slot:[`item.valor_saldo`]="{ item }">
          <span> R$ {{ item.valor_saldo | formatNumber}} </span>
        </template>

        <template v-if="items.length>0" slot="body.append">
          <tr class="custom-table--header">
            <th style="font-size: 12px;">Total</th>
            <th class="text-left" style="font-size: 12px;">R$ {{ soma(items, 'valor_orcado') | formatNumber }}</th>
            <th class="text-left" style="font-size: 12px;">R$ {{ soma(items, 'valor_pago') | formatNumber }}</th>
            <th class="text-left" style="font-size: 12px;">R$ {{ soma(items, 'valor_saldo') | formatNumber }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <custom-snackbar ref="snackbar"/>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import EditarPedidoRouteasyComponent from "@/view/pages/fretes/routeasy/components/EditarPedidoRouteasyComponent";

export default {
  name: 'ListagemContaCorrente',

  components: {EditarPedidoRouteasyComponent},

  props: {
    disablePagination: {
      type: Boolean,
      default: false,
    },
    filtros: {
      type: Object,
    },
    headers: {
      type: Object,
    },
  },

  data: () => ({
    items: [],
    loadingItens: true,
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.carregarLista()
    },
  },

  methods: {
    async carregarLista () {
      this.loadingItens = true
      this.items = []

      try {
        let result = []

        if (this.filtros.tipoRelatorioId === 1) {
          result = (await ApiService.get('fretes/conta-corrente/diario', this.filtros)).data
        } else {
          result = (await ApiService.get('fretes/conta-corrente/producao', this.filtros)).data
        }

        this.items = result.data
      } catch (e) {
        this.$refs.snackbar.show(
          'Erro',
          'Não foi carregar as informações.',
          'danger',
        )
      }

      this.loadingItens = false
    },

    soma (items, index) {
      let sum = 0

      items.forEach((item) => {
        sum += item[index]
      })

      return sum
    },
  },

  mounted () {
    this.carregarLista()
  },
}
</script>
