<template>
  <v-dialog v-model="opened" persistent max-width="500">
    <v-card :disabled="form.processing" :loading="form.processing">
      <v-card-subtitle class="title pt-3">
        Este pedido ainda não foi roteirizado. Você deseja informar o status manualmente?
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close">Não</v-btn>
        <v-btn color="primary" dark depressed @click="save">Sim</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  data: () => ({
    form: {
      routeasy_order_id: null,
      status: 'on_hold',
      processing: false,
    },
    opened: false,
    selects: {
      status: [{
        id: 'on_hold',
        description: 'Roteirizado',
        color: 'blue'
      }, {
        id: 'on_route',
        description: 'Em trânsito',
      }, {
        id: 'arrived',
        description: 'No local',
      }, {
        id: 'servicing',
        description: 'Descarregando',
      }, {
        id: 'completed',
        description: 'Finalizado',
      }]
    }
  }),

  methods: {
    open (routeasy_order_id) {
      this.form.routeasy_order_id = routeasy_order_id
      this.opened = true
    },

    close () {
      this.form.routeasy_order_id = null
      this.opened = false
      this.$emit('close')
    },

    async save () {
      this.form.processing = true

      try {
        const result = await ApiService.post('fretes/routeasy/salvar-eventos', this.form)

        this.$emit('save', result.data)

        this.$refs.snackbar.show(
            'Deu tudo certo!',
            'A sua requisição foi atendida com sucesso.',
        )
      } catch (e) {
        if (e.response) {
          if (e.response.data.errors) {
            this.form.errors = e.response.data.errors
          }

          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              e.response.data.message,
              'danger',
          )
        } else {
          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Não foi possível atender a sua requisição.',
              'danger',
          )
        }
      }

      this.form.processing = false

      this.close()
    }
  }
}
</script>