<template>
  <div>
    <actions-listagem-conta-corrente v-model="headers" :filtro="filtro" :items-per-page="itemsPerPage" @itemsPerPage="itemsPerPage = $event"/>
    <filter-listagem-conta-corrente v-model="filtro"/>
    <listagem-conta-corrente :filtros="filtro" :headers="headers" :items-per-page="itemsPerPage"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ActionsListagemContaCorrente
  from "@/view/pages/fretes/conta-corrente/components/ActionsListagemContaCorrente.vue";
import FilterListagemContaCorrente from "@/view/pages/fretes/conta-corrente/components/FilterListagemContaCorrente.vue";
import ListagemContaCorrente from "@/view/pages/fretes/conta-corrente/components/ListagemContaCorrente.vue";

export default {
  components: {
    FilterListagemContaCorrente,
    ActionsListagemContaCorrente,
    ListagemContaCorrente,
  },

  data: (instance) => ({
    filtro: {
      tipoRelatorioId: instance.$route.query.tipoRelatorioId ? parseInt(instance.$route.query.tipoRelatorioId) : 1,
      empresaId: instance.$route.query.empresaId ? parseInt(instance.$route.query.empresaId) : null,
      producaoId: instance.$route.query.producaoId ? parseInt(instance.$route.query.producaoId) : null,
      dataInicial: instance.$route.query.dataInicial ? instance.$route.query.dataInicial : instance.$date.addDays(null, -30),
      dataFinal: instance.$route.query.dataFinal ? instance.$route.query.dataFinal : instance.$date.addDays(null, 0),
    },
    headers: [],
    itemsPerPage: 100,
  }),

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes' },
      { title: 'Conta Corrente', route: 'conta-corrente' },
    ])
  },
}
</script>
